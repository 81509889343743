import React from 'react';
import { Helmet } from 'react-helmet-async';

const baseUrl = "https://www.doclee.cz";

export const LandingPageSEO = () => (
    <Helmet>
        <title>DOC LEE - Automatizace zpracování dokumentů pacientů | Úspora času pro zdravotnický personál</title>
        <meta name="description" content="DOC LEE automatizuje zpracování dokumentů pacientů, šetří až 15 hodin týdně zdravotnickému personálu a zvyšuje efektivitu zdravotnických zařízení." />
        <link rel="canonical" href={baseUrl} />

        <script type="application/ld+json">
            {`
        {
          "@context": "https://schema.org",
          "@graph": [
            {
              "@type": "WebPage",
              "@id": "https://www.doclee.cz/#webpage",
              "url": "https://www.doclee.cz",
              "name": "DOC LEE - Automatizace zpracování dokumentů pacientů",
              "description": "DOC LEE automatizuje zpracování dokumentů pacientů, šetří až 15 hodin týdně zdravotnickému personálu a zvyšuje efektivitu zdravotnických zařízení.",
              "isPartOf": {
                "@id": "https://www.doclee.cz/#website"
              }
            },
            {
              "@type": "SoftwareApplication",
              "name": "DOC LEE",
              "applicationCategory": "HealthcareApplication",
              "operatingSystem": "Web",
              "offers": {
                "@type": "Offer",
                "price": "0",
                "priceCurrency": "CZK"
              },
              "featureList": [
                "Automatické zpracování dokumentů",
                "Úspora času až 15 hodin týdně",
                "Přehledná organizace informací",
                "Podklady pro pojišťovny"
              ]
            }
          ]
        }
      `}
        </script>
    </Helmet>
);

export const HowToPageSEO = () => (
    <Helmet>
        <title>Jak to funguje | DOC LEE - Průvodce automatizací zdravotnické dokumentace</title>
        <meta name="description" content="Zjistěte, jak DOC LEE automatizuje zpracování zdravotnické dokumentace a šetří čas lékařům a sestrám." />
        <link rel="canonical" href={`${baseUrl}/jak-to-funguje`} />

        <script type="application/ld+json">
            {`
        {
          "@context": "https://schema.org",
          "@graph": [
            {
              "@type": "WebPage",
              "@id": "https://www.doclee.cz/jak-to-funguje/#webpage",
              "url": "https://www.doclee.cz/jak-to-funguje",
              "name": "Jak to funguje | DOC LEE",
              "isPartOf": {
                "@id": "https://www.doclee.cz/#website"
              }
            },
            {
              "@type": "HowTo",
              "name": "Jak používat DOC LEE pro zpracování dokumentace",
              "description": "Průvodce používáním systému DOC LEE pro automatizaci zpracování zdravotnické dokumentace",
              "step": [
                {
                  "@type": "HowToStep",
                  "name": "Nahrání dokumentů",
                  "text": "Zkopírujte soubory pacientů do speciální složky ve svém počítači.",
                  "position": "1"
                },
                {
                  "@type": "HowToStep",
                  "name": "Automatické zpracování",
                  "text": "Systém automaticky zpracuje a analyzuje nahrané dokumenty.",
                  "position": "2"
                },
                {
                  "@type": "HowToStep",
                  "name": "Přehled výsledků",
                  "text": "Získejte přehledně zpracovaná data vhodná pro analýzu a další použití.",
                  "position": "3"
                }
              ]
            }
          ]
        }
      `}
        </script>
    </Helmet>
);

export const ScheduleAppointmentSEO = () => (
    <Helmet>
        <title>Naplánovat konzultaci | DOC LEE</title>
        <meta name="description" content="Naplánujte si konzultaci s týmem DOC LEE. Vysvětlíme vám, jak můžete automatizovat zpracování dokumentů a ušetřit až 15 hodin týdně." />
        <link rel="canonical" href={`${baseUrl}/konzultace`} />

        <script type="application/ld+json">
            {`
        {
          "@context": "https://schema.org",
          "@graph": [
            {
              "@type": "WebPage",
              "@id": "https://www.doclee.cz/konzultace/#webpage",
              "url": "https://www.doclee.cz/konzultace",
              "name": "Naplánovat konzultaci | DOC LEE",
              "isPartOf": {
                "@id": "https://www.doclee.cz/#website"
              }
            },
            {
              "@type": "Service",
              "name": "Konzultace DOC LEE",
              "serviceType": "Professional Consultation",
              "provider": {
                "@id": "https://www.doclee.cz/#organization"
              },
              "description": "Odborná konzultace k automatizaci zpracování zdravotnické dokumentace",
              "availableChannel": {
                "@type": "ServiceChannel",
                "serviceUrl": "https://www.doclee.cz/konzultace",
                "servicePhone": "+420"
              }
            },
            {
              "@type": "Schedule",
              "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
              "startTime": "00:00",
              "endTime": "23:00"
            }
          ]
        }
      `}
        </script>
    </Helmet>
);
