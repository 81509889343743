import React from 'react';
import { ArrowLeft, Shield, CheckCircle, AlertCircle, Eye, FileCheck, Database, Zap, BarChart, Lock, FileSearch } from 'lucide-react';
import { Link } from 'react-router-dom';
import DocLeeLogo from "./DocleeLogo";

const ValidationProcessPage = () => {
    return (
        <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white font-sans">
            <header className="bg-white shadow-md">
                <div className="container mx-auto px-6 py-4 flex justify-between items-center">
                    <DocLeeLogo />
                    <Link to="/" className="flex items-center text-blue-600 hover:text-blue-800">
                        <ArrowLeft className="w-5 h-5 mr-2"/>
                        Zpět na hlavní stránku
                    </Link>
                </div>
            </header>

            <main className="container mx-auto px-6 py-12">
                <div className="max-w-4xl mx-auto">
                    {/* Hero Section with Statistics */}
                    <div className="text-center mb-12">
                        <h2 className="text-3xl font-bold text-gray-800 mb-4">
                            Přesnost a bezpečnost v každém kroku
                        </h2>
                        <p className="text-xl text-gray-600 mb-8">
                            DOC LEE kombinuje umělou inteligenci s lidskou expertízou pro maximální přesnost při zpracování zdravotnické dokumentace.
                        </p>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                            <StatCard
                                number="99.9%"
                                description="Přesnost extrakce dat"
                                icon={<BarChart className="w-6 h-6 text-blue-600" />}
                            />
                            <StatCard
                                number="100%"
                                description="GDPR kompatibilní"
                                icon={<Lock className="w-6 h-6 text-blue-600" />}
                            />
                            <StatCard
                                number="<20s"
                                description="Průměrná doba zpracování"
                                icon={<Zap className="w-6 h-6 text-blue-600" />}
                            />
                        </div>
                    </div>

                    {/* Process Overview */}
                    <div className="bg-white p-8 rounded-lg shadow-md mb-12">
                        <h3 className="text-2xl font-bold text-gray-800 mb-6">Jak probíhá zpracování dokumentu</h3>
                        <div className="space-y-8">
                            <ProcessStep
                                number="1"
                                title="Nahrání dokumentu"
                                description="Dokument lze nahrát přes webové rozhraní nebo automaticky ze sledované složky."
                                details={[
                                    "Podpora formátů PDF, JPEG, PNG, TIFF",
                                    "Automatická detekce typu dokumentu",
                                    "Okamžitá kontrola kvality obrazu"
                                ]}
                            />
                            <ProcessStep
                                number="2"
                                title="AI zpracování"
                                description="Pokročilé OCR a AI modely extrahují a strukturují data."
                                details={[
                                    "Vícevrstvá validace extrahovaných dat",
                                    "Kontextové porozumění obsahu",
                                    "Automatická kategorizace informací"
                                ]}
                            />
                            <ProcessStep
                                number="3"
                                title="Kontrola lékařem"
                                description="Přehledné rozhraní pro rychlou kontrolu a validaci dat."
                                details={[
                                    "Side-by-side porovnání s originálem",
                                    "Zvýraznění nejistých částí",
                                    "Jednoduchý proces potvrzení správnosti"
                                ]}
                            />
                            <ProcessStep
                                number="4"
                                title="Export a integrace"
                                description="Validovaná data jsou připravena pro další použití."
                                details={[
                                    "Export do různých formátů (JSON, XML, CSV)",
                                    "Přímá integrace s nemocničními systémy",
                                    "Automatické aktualizace pacientských záznamů"
                                ]}
                            />
                        </div>
                    </div>

                    {/* Validation Features */}
                    <div className="grid md:grid-cols-2 gap-8 mb-12">
                        <ValidationFeature
                            icon={<FileSearch className="w-8 h-8 text-blue-600" />}
                            title="Inteligentní validace"
                            description="Systém automaticky kontroluje konzistenci dat napříč dokumenty a označuje potenciální nesrovnalosti."
                        />
                        <ValidationFeature
                            icon={<Shield className="w-8 h-8 text-blue-600" />}
                            title="Bezpečnostní protokoly"
                            description="Veškerá komunikace je šifrována a data jsou zpracovávána v souladu s GDPR a zdravotnickými předpisy."
                        />
                        <ValidationFeature
                            icon={<Database className="w-8 h-8 text-blue-600" />}
                            title="Auditní záznamy"
                            description="Kompletní historie všech operací včetně časových značek a identifikace uživatelů."
                        />
                        <ValidationFeature
                            icon={<CheckCircle className="w-8 h-8 text-blue-600" />}
                            title="Certifikované procesy"
                            description="Všechny procesy jsou navrženy v souladu s ISO 27001 a zdravotnickými standardy."
                        />
                    </div>

                    {/* Legal Section */}
                    <div className="bg-blue-50 p-8 rounded-lg shadow-md mb-12">
                        <h3 className="text-2xl font-bold text-gray-800 mb-4">Právní aspekty a odpovědnost</h3>
                        <div className="space-y-4">
                            <LegalPoint
                                title="Odpovědnost za data"
                                description="Systém slouží jako pomocný nástroj, konečná odpovědnost zůstává na zdravotnickém pracovníkovi."
                            />
                            <LegalPoint
                                title="Ochrana osobních údajů"
                                description="Zpracování v souladu s GDPR a zákonem o zdravotních službách."
                            />
                            <LegalPoint
                                title="Digitální podpisy"
                                description="Každá validace je opatřena digitálním podpisem odpovědné osoby."
                            />
                            <LegalPoint
                                title="Archivace"
                                description="Automatické uchovávání originálů i zpracovaných dat dle zákonných požadavků."
                            />
                        </div>
                    </div>

                    {/* CTA Section */}
                    <section className="text-center">
                        <h3 className="text-2xl font-bold text-gray-800 mb-4">Začněte s DOC LEE</h3>
                        <p className="text-gray-600 mb-6">
                            Vyzkoušejte, jak může DOC LEE zefektivnit vaši práci při zachování maximální bezpečnosti a přesnosti.
                        </p>
                        <div className="flex flex-col sm:flex-row justify-center gap-4">
                            <Link
                                to="/konzultace"
                                className="bg-blue-600 text-white font-bold py-3 px-6 rounded-lg hover:bg-blue-700 transition duration-300"
                            >
                                Domluvit předvedení
                            </Link>
                            <a
                                href="https://app.doclee.cz/auth-ui/register"
                                className="border-2 border-blue-600 text-blue-600 font-bold py-3 px-6 rounded-lg hover:bg-blue-50 transition duration-300"
                            >
                                Vyzkoušet zdarma
                            </a>
                        </div>
                    </section>
                </div>
            </main>

            <footer className="bg-gray-100 py-6 mt-12">
                <div className="container mx-auto px-6 text-center text-gray-600">
                    &copy; 2024 DOC LEE. Všechna práva vyhrazena.
                </div>
            </footer>
        </div>
    );
};

const StatCard = ({ number, description, icon }) => (
    <div className="bg-white p-6 rounded-lg shadow-md">
        <div className="flex items-center justify-center mb-2">
            {icon}
        </div>
        <div className="text-2xl font-bold text-gray-800 mb-1">{number}</div>
        <div className="text-sm text-gray-600">{description}</div>
    </div>
);

const ProcessStep = ({ number, title, description, details }) => (
    <div className="flex gap-4">
        <div className="flex-shrink-0 w-8 h-8 bg-blue-600 text-white rounded-full flex items-center justify-center font-bold">
            {number}
        </div>
        <div>
            <h4 className="font-semibold text-gray-800 mb-2">{title}</h4>
            <p className="text-gray-600 mb-2">{description}</p>
            <ul className="list-disc list-inside text-sm text-gray-500">
                {details.map((detail, index) => (
                    <li key={index}>{detail}</li>
                ))}
            </ul>
        </div>
    </div>
);

const ValidationFeature = ({ icon, title, description }) => (
    <div className="bg-white p-6 rounded-lg shadow-md">
        <div className="flex items-center mb-4">
            {icon}
            <h4 className="text-lg font-semibold text-gray-800 ml-3">{title}</h4>
        </div>
        <p className="text-gray-600">{description}</p>
    </div>
);

const LegalPoint = ({ title, description }) => (
    <div className="mb-4">
        <h4 className="font-semibold text-gray-800 mb-1">{title}</h4>
        <p className="text-gray-600">{description}</p>
    </div>
);

export default ValidationProcessPage;