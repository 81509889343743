import React from 'react';
import { FileText, Users, Shield, Clock, CheckCircle, Zap, ArrowRight, Calendar } from 'lucide-react';
import { Link } from 'react-router-dom';
import DocLeeLogo from "./DocleeLogo";
import HeaderAnimation from "./HeaderAnimation";
import ContactForm from "./ContactForm";
import {LandingPageSEO} from "../SEO/SEOHelmet";

const MainLandingPage = () => {
    return (
        <>
            <LandingPageSEO />
            <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white font-sans">
                <header className="bg-white shadow-md">
                    <div className="container mx-auto px-6 py-4 flex justify-between items-center">
                        <DocLeeLogo />
                        <nav>
                            <ul className="flex space-x-6">
                                <li><a href="#funkce" className="text-gray-600 hover:text-blue-600">Funkce</a></li>
                                <li><a href="#vyhody" className="text-gray-600 hover:text-blue-600">Výhody</a></li>
                                <li><a href="#kontakt" className="text-gray-600 hover:text-blue-600">Kontakt</a></li>
                                <li><Link to="/jak-to-funguje" className="text-gray-600 hover:text-blue-600">Jak to funguje</Link></li>
                            </ul>
                        </nav>
                    </div>
                </header>

                <main className="container mx-auto px-6 py-12">
                    <section className="mb-16 flex flex-col md:flex-row items-center">
                        <div className="md:w-1/2 text-center md:text-left mb-8 md:mb-0 md:pr-8">
                            <h2 className="text-4xl font-bold text-gray-800 mb-4">Lékaři a sestry, kteří zvolili DOC LEE potvrzují: hodina práce se mění na 10minut!</h2>
                            <p className="text-xl text-gray-600 mb-8">
                                Zkopírujete soubory pacientů do speciální složky ve svém počítači.<br/>
                                Automaticky obdržíte systematizovaná data vhodna pro analýzu a zpracování! Super snadné použití.<br/>
                            </p>
                            <div className="flex flex-col sm:flex-row items-center justify-center md:justify-start space-y-4 sm:space-y-0 sm:space-x-4">
                                <a
                                    href="https://app.doclee.cz/auth-ui/register"
                                    className="group bg-blue-600 text-white font-bold py-3 px-6 rounded-lg hover:bg-blue-700 transition duration-300 w-full sm:w-auto text-center inline-flex items-center justify-center"
                                >
                                    Vyzkoušet zdarma <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform duration-200" />
                                </a>
                                <a
                                    href="/konzultace"
                                    className="border-2 border-blue-600 text-blue-600 font-bold py-3 px-6 rounded-lg hover:bg-blue-50 transition duration-300 w-full sm:w-auto text-center"
                                >
                                    Domluvit předvedení
                                </a>
                            </div>
                        </div>
                        <div className="md:w-1/2 hidden md:block">
                            <HeaderAnimation />
                        </div>
                    </section>

                    <section id="casova-uspora" className="mb-16 bg-blue-50 p-8 rounded-lg shadow-md">
                        <div className="flex items-center justify-center mb-6">
                            <Clock className="w-12 h-12 text-blue-600 mr-4" />
                            <h3 className="text-3xl font-bold text-gray-800">Ušetřete až 15 hodin týdně</h3>
                        </div>
                        <p className="text-xl text-center text-gray-700">
                            Lékaři používající DOC LEE uvádějí, že ušetří průměrně 2-3 hodiny denně na administrativě.
                            To znamená více času na péči o pacienty a méně přesčasů.
                        </p>
                    </section>

                    <section id="funkce" className="mb-16">
                        <h3 className="text-2xl font-bold text-gray-800 mb-6 text-center">Hlavní funkce</h3>
                        <div className="grid md:grid-cols-3 gap-8">
                            <FeatureCard
                                icon={<FileText className="w-12 h-12 text-blue-600" />}
                                title="Automatické zpracování dokumentů"
                                description="Rychlé zpracování výsledků vyšetření a lékařských zpráv."
                            />
                            <FeatureCard
                                icon={<Users className="w-12 h-12 text-blue-600" />}
                                title="Přehledná organizace informací"
                                description="Všechny údaje o pacientovi na jednom místě."
                            />
                            <FeatureCard
                                icon={<Shield className="w-12 h-12 text-blue-600" />}
                                title="Podklady pro pojišťovny"
                                description="Snadné doložení nutnosti léčby zdravotním pojišťovnám."
                            />
                        </div>
                    </section>

                    <section id="vyhody" className="mb-16 flex flex-col items-center">
                        <h3 className="text-2xl font-bold text-gray-800 mb-6 text-center">Výhody pro vaši praxi</h3>
                        <ul className="space-y-4 inline-block">
                            <BenefitItem icon={<Clock className="w-6 h-6 text-green-500 flex-shrink-0" />} text="Méně času stráveného administrativou" />
                            <BenefitItem icon={<CheckCircle className="w-6 h-6 text-green-500 flex-shrink-0" />} text="Snížení chybovosti v dokumentaci" />
                            <BenefitItem icon={<Users className="w-6 h-6 text-green-500 flex-shrink-0" />} text="Lepší spolupráce mezi zdravotníky" />
                            <BenefitItem icon={<Shield className="w-6 h-6 text-green-500 flex-shrink-0" />} text="Zjednodušení procesu s pojišťovnami" />
                            <BenefitItem icon={<Zap className="w-6 h-6 text-green-500 flex-shrink-0" />} text="Zvýšená efektivita v období chřipkových epidemií" />
                        </ul>
                    </section>

                    <section id="kontakt-section" className="text-center mb-16">
                        <h3 className="text-2xl font-bold text-gray-800 mb-6">Chcete zefektivnit vaši praxi?</h3>
                        <p className="text-xl text-gray-600 mb-8">
                            Vyplňte formulář níže a my vás budeme kontaktovat s dalšími informacemi o tom, jak může DOC LEE pomoci právě vám.
                        </p>

                        <div className="max-w-lg mx-auto mb-8 bg-blue-50 p-6 rounded-lg shadow-sm">
                            <div className="flex items-center justify-center mb-4">
                                <Calendar className="w-6 h-6 text-blue-600 mr-2" />
                                <h4 className="text-lg font-semibold text-gray-800">Preferujete telefonickou konzultaci?</h4>
                            </div>
                            <p className="text-gray-600 mb-4">
                                Můžete si naplánovat hovor v čase, který vám vyhovuje.
                            </p>
                            <Link
                                to="/konzultace"
                                className="inline-flex items-center justify-center bg-white text-blue-600 font-semibold py-3 px-6 rounded-lg hover:bg-blue-50 transition duration-300 border-2 border-blue-600"
                            >
                                Naplánovat hovor
                            </Link>
                        </div>

                        <ContactForm />
                    </section>
                </main>

                <footer className="bg-gray-100 py-6">
                    <div className="container mx-auto px-6 text-center text-gray-600">
                        &copy; 2024 DOC LEE. Všechna práva vyhrazena.
                    </div>
                </footer>
            </div>
        </>
    );
};

const FeatureCard = ({ icon, title, description }) => (
    <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center text-center h-full">
        <div className="mb-4">{icon}</div>
        <h4 className="text-xl font-semibold mb-2">{title}</h4>
        <p className="text-gray-600">{description}</p>
    </div>
);

const BenefitItem = ({ icon, text }) => (
    <li className="flex items-start space-x-3">
        <span className="mt-1">{icon}</span>
        <span className="text-gray-700">{text}</span>
    </li>
);

export default MainLandingPage;