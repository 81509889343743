import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Analytics } from "@vercel/analytics/react";
import { useTracking } from './hooks/useEmailTracking';
import LandingPage from './components/LandingPage';
import HowItWorksPage from './components/HowItWorksPage';
import ScheduleAppointmentPage from "./components/ScheduleAppointment";
import {HelmetProvider} from "react-helmet-async";
import React from "react";
import ValidationProcessPage from "./components/ValidationProcessPage";

const TrackingWrapper = ({ children }) => {
    useTracking();
    return <>{children}</>;
};

function App() {
    return (

        <HelmetProvider>
            <Router>
                <TrackingWrapper>
                    <Routes>
                        <Route exact path="/" element={<LandingPage />} />
                        <Route path="/jak-to-funguje" element={<HowItWorksPage />} />
                        <Route path="/kontrolni-proces" element={<ValidationProcessPage />} />
                        <Route path="/konzultace" element={<ScheduleAppointmentPage />} />
                    </Routes>
                </TrackingWrapper>
                <Analytics />
            </Router>
        </HelmetProvider>
    );
}

export default App;
