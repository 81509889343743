import React from 'react';

const DocLeeLogo = ({ className = "" }) => {
    return (
        <div className={`flex items-center ${className}`}>
            <div className="w-8 h-8 mr-2 relative">
                <img src="logo192.png" alt="" />
            </div>
            <span className="text-xl font-bold">
        DOC <span className="text-green-500">LEE</span>
      </span>
        </div>
    );
};

export default DocLeeLogo;