import React, { useState, useEffect } from 'react';
import { Check, Copy } from 'lucide-react';

// Configuration for document images
const documentConfig = [
    { url: 'input-file1.jpg', delay: 0 },
    { url: 'input-file2.png', delay: 2.67 },
    { url: 'input-file3.jpeg', delay: 5.33 },
];

const HeaderAnimation = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [selectedText, setSelectedText] = useState('');
    const [copied, setCopied] = useState(false);
    const [selectionProgress, setSelectionProgress] = useState(0);
    const [showCopyAnimation, setShowCopyAnimation] = useState(false);

    const textToSelect = "Nebyly zjištěny žádné známky nádorového bujení ani cévní malformace.";

    useEffect(() => {
        const showPopupInterval = () => {
            setShowPopup(true);
            setSelectionProgress(0);
            setCopied(false);
            setShowCopyAnimation(false);

            // Start text selection after 1 second
            setTimeout(() => {
                const selectionInterval = setInterval(() => {
                    setSelectionProgress(prev => {
                        if (prev < textToSelect.length) return prev + 1;
                        clearInterval(selectionInterval);

                        // Animate copy button after 1 second
                        setTimeout(() => {
                            setShowCopyAnimation(true);

                            // Reset copy button after 2 seconds
                            setTimeout(() => {
                                setShowCopyAnimation(false);
                            }, 2000);
                        }, 1000);

                        return prev;
                    });
                }, 50); // Adjust speed of selection here
            }, 1000);

            // Hide popup after 10 seconds
            setTimeout(() => {
                setShowPopup(false);
            }, 10000);
        };

        // Initial delay of 5 seconds before first popup
        const initialTimeout = setTimeout(() => {
            showPopupInterval();
            // Start the interval after the initial timeout
            setInterval(showPopupInterval, 15000);
        }, 5000);

        return () => {
            clearTimeout(initialTimeout);
            clearInterval(showPopupInterval);
        };
    }, []);

    const handleCopy = () => {
        navigator.clipboard.writeText(textToSelect);
        setCopied(true);
    };

    return (
        <div className="relative w-full h-full">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 300" className="w-full h-full">
                <defs>
                    <linearGradient id="screen-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="#f0f9ff"/>
                        <stop offset="100%" stopColor="#e0f2fe"/>
                    </linearGradient>
                </defs>

                {/* Scanner */}
                <g transform="translate(30, 100)" filter="url(#shadow)">
                    <rect x="0" y="0" width="100" height="80" rx="8" fill="#4b5563"/>
                    <rect x="5" y="5" width="90" height="60" rx="4" fill="#d1d5db"/>
                    <rect x="70" y="70" width="25" height="10" rx="2" fill="#9ca3af"/>
                    <rect x="5" y="10" width="90" height="5" fill="#34d399" className="animate-scanLight"/>
                </g>

                {/* Moving Documents (now as images) */}
                {documentConfig.map((doc, index) => (
                    <g key={index} className={`animate-moveDocument opacity-0`} style={{animationDelay: `${doc.delay}s`}}>
                        <image href={doc.url} x="35" y="105" width="90" height="70"/>
                    </g>
                ))}

                {/* Computer with Alternating Screens */}
                <g transform="translate(250, 60)" filter="url(#shadow)">
                    <rect x="0" y="0" width="120" height="90" rx="10" fill="#1e40af"/>
                    <rect x="3" y="3" width="114" height="84" rx="8" fill="#3b82f6"/>
                    <rect x="6" y="6" width="108" height="78" rx="6" fill="url(#screen-gradient)"/>
                    <path d="M50 90 L70 90 L65 110 L55 110 Z" fill="#1e40af"/>
                    <rect x="40" y="110" width="40" height="6" rx="3" fill="#1e40af"/>

                    {/* Screen 1: Patient List */}
                    <g className="animate-showScreen1">
                        <text x="10" y="20" fontSize="8" fill="#1e40af" fontWeight="bold">Patient List</text>
                        <line x1="10" y1="25" x2="108" y2="25" stroke="#3b82f6" strokeWidth="1"/>

                        {/* Table Headers */}
                        <text x="20" y="35" fontSize="6" fill="#1e40af">Name</text>
                        <text x="70" y="35" fontSize="6" fill="#1e40af">Birth Number</text>

                        {/* Patient Rows */}
                        {['John Doe', 'Eva Satranova', 'Katerina Hola'].map((name, index) => (
                            <g key={index} transform={`translate(0, ${45 + index * 10})`}>
                                <path d={`M10,4 L14,4 L12,0 Z`} fill="#f59e0b" className={`animate-showWarning opacity-0`} style={{animationDelay: `${index * 2.67}s`}}/>
                                <text x="12" y="3.5" fontSize="3" fill="#ffffff" textAnchor="middle">!</text>
                                <text x="20" y="5" fontSize="6" fill="#4b5563">{name}</text>
                                <text x="70" y="5" fontSize="6" fill="#4b5563">{`${760501 + index * 100000}/1234`}</text>
                            </g>
                        ))}
                    </g>

                    {/* Screen 2: Single Patient File List */}
                    <g className="animate-showScreen2">
                        <text x="10" y="20" fontSize="8" fill="#1e40af" fontWeight="bold">John Doe</text>
                        <text x="10" y="30" fontSize="6" fill="#4b5563">Birth Number: 760501/1234</text>
                        <line x1="10" y1="35" x2="108" y2="35" stroke="#3b82f6" strokeWidth="1"/>

                        {/* Table Headers */}
                        <text x="20" y="42" fontSize="6" fill="#1e40af">File Name</text>
                        <text x="85" y="42" fontSize="6" fill="#1e40af">Status</text>

                        {/* File Rows */}
                        {[
                            { name: 'Blood Test Results', status: 'Checked', color: '#10b981' },
                            { name: 'X-Ray Report', status: 'Unchecked', color: '#ef4444' },
                            { name: 'Prescription', status: 'Checked', color: '#10b981' },
                            { name: 'MRI Scan', status: 'Unchecked', color: '#ef4444' },
                        ].map((file, index) => (
                            <g key={index} transform={`translate(0, ${48 + index * 10})`}>
                                <circle cx="15" cy="0" r="2" fill={file.color}/>
                                <text x="20" y="3" fontSize="5" fill="#4b5563">{file.name}</text>
                                <text x="85" y="3" fontSize="5" fill={file.color}>{file.status}</text>
                            </g>
                        ))}
                    </g>
                </g>
            </svg>

            {/* Popup with MRI Results and Image Preview */}
            {showPopup && (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex animate-slideUp">
                    {/* Image Preview */}
                    <div className="w-80 h-80 bg-gray-200 rounded-lg overflow-hidden mr-4 shadow-lg">
                        <img src="input-file.jpg" alt="MRI Preview" className="w-full h-full object-cover" />
                    </div>

                    {/* Popup Content */}
                    <div className="w-80 bg-white shadow-lg rounded-lg overflow-hidden">
                        <div className="bg-blue-600 text-white py-2 px-4 flex justify-between items-center">
                            <h3 className="text-lg font-bold">Výsledky MRI</h3>
                            <span className="text-sm">28.09.2024</span>
                        </div>
                        <div className="p-4">
                            <div className="mb-2">
                                <span className="font-semibold">Pacient:</span> Jan Novák (760501/1234)
                            </div>
                            <div className="mt-4 p-2 bg-gray-100 rounded select-text relative">
                                <p>
                                    MRI mozku prokázalo mírnou atrofii frontálního a temporálního laloku.
                                    <span className="relative">
                                        {textToSelect.split('').map((char, index) => (
                                            <span
                                                key={index}
                                                className={index < selectionProgress ? 'bg-blue-200' : ''}
                                            >
                                                {char}
                                            </span>
                                        ))}
                                    </span>
                                    Doporučeno sledování a kontrolní MRI za 6 měsíců.
                                </p>
                                {copied && (
                                    <Check className="absolute top-0 right-0 w-5 h-5 text-green-500 animate-fadeIn" />
                                )}
                            </div>
                            <button
                                className={`mt-2 px-3 py-1 rounded flex items-center transition-colors duration-300 ${
                                    copied ? 'bg-green-500 text-white' :
                                        showCopyAnimation ? 'bg-blue-500 text-white' :
                                            'bg-gray-200 text-gray-700 hover:bg-blue-500 hover:text-white'
                                }`}
                                onClick={handleCopy}
                            >
                                {copied ? <Check className="w-4 h-4 mr-1" /> : <Copy className="w-4 h-4 mr-1" />}
                                {copied ? 'Zkopírováno' : 'Kopírovat'}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <style jsx>{`
                @keyframes scanLight {
                    0%, 100% { transform: translateY(0); }
                    50% { transform: translateY(50px); }
                }
                @keyframes moveDocument {
                    0%, 5% { transform: translate(0, 0); opacity: 0; }
                    10% { transform: translate(0, 0); opacity: 1; }
                    20% { transform: translate(0, -30px); opacity: 1; }
                    40% { transform: translate(100px, -30px); opacity: 1; }
                    60% { transform: translate(100px, -30px); opacity: 1; }
                    90%, 100% { transform: translate(200px, -30px); opacity: 1; }
                }
                @keyframes showWarning {
                    0%, 90% { opacity: 0; }
                    95%, 100% { opacity: 1; }
                }
                @keyframes showScreen1 {
                    0%, 47% { opacity: 1; }
                    48%, 100% { opacity: 0; }
                }
                @keyframes showScreen2 {
                    0%, 47% { opacity: 0; }
                    48%, 50% { opacity: 0; }
                    51%, 98% { opacity: 1; }
                    99%, 100% { opacity: 0; }
                }
                @keyframes slideUp {
                    from { transform: translate(-50%, -40%); opacity: 0; }
                    to { transform: translate(-50%, -50%); opacity: 1; }
                }
                @keyframes fadeIn {
                    from { opacity: 0; }
                    to { opacity: 1; }
                }
                .animate-scanLight { animation: scanLight 3s infinite; }
                .animate-moveDocument { animation: moveDocument 8s infinite; }
                .animate-showWarning { animation: showWarning 8s infinite; }
                .animate-showScreen1 { animation: showScreen1 10s infinite; }
                .animate-showScreen2 { animation: showScreen2 10s infinite; }
                .animate-slideUp { animation: slideUp 0.5s ease-out forwards; }
                .animate-fadeIn { animation: fadeIn 0.3s ease-in-out; }
            `}</style>
        </div>
    );
};

export default HeaderAnimation;