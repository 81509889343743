import React, { useState } from 'react';
import { Mail, Phone, MessageSquare, SendHorizontal, RefreshCw } from 'lucide-react';
import API_URL from "../config";
import {useVisitorId} from "../hooks/useEmailTracking";

const DEFAULT_MESSAGE = `Dobrý den,

mám zájem o vyzkoušení DOC LEE pro naši ordinaci.

Prosím o kontaktování ohledně představení vašeho řešení a domluvení dalších kroků.

Děkuji`;

const ContactForm = () => {
    const [formData, setFormData] = useState({
        formEmail: '',
        formPhoneNumber: '',
        subject: 'Zájem o DOC LEE - Žádost o představení',
        message: DEFAULT_MESSAGE
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatus, setSubmitStatus] = useState('idle');
    const visitorId = useVisitorId();  // Use the hook

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const response = await fetch(API_URL+'/api/customer/contact-form', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setSubmitStatus('success');
                setFormData({
                    formEmail: '',
                    formPhoneNumber: '',
                    subject: 'Zájem o DOC LEE - Žádost o představení',
                    message: DEFAULT_MESSAGE,
                    visitorId
                });
            } else {
                setSubmitStatus('error');
            }
        } catch (error) {
            setSubmitStatus('error');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} id="kontakt" className="max-w-lg mx-auto bg-white p-8 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">Kontaktujte nás</h2>
            <p className="text-gray-600 mb-6 text-center">Máte zájem vyzkoušet DOC LEE? Napište nám a my se vám ozveme do 24 hodin.</p>

            <div className="mb-4">
                <label htmlFor="email" className="flex items-center text-gray-700 font-semibold mb-2">
                    <Mail className="w-4 h-4 mr-2" />
                    Email
                </label>
                <input
                    type="email"
                    id="email"
                    required
                    value={formData.formEmail}
                    onChange={(e) => setFormData(prev => ({ ...prev, formEmail: e.target.value }))}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="vas@email.cz"
                />
            </div>

            <div className="mb-4">
                <label htmlFor="phone" className="flex items-center text-gray-700 font-semibold mb-2">
                    <Phone className="w-4 h-4 mr-2" />
                    Telefon (nepovinné)
                </label>
                <input
                    type="tel"
                    id="phone"
                    value={formData.formPhoneNumber}
                    onChange={(e) => setFormData(prev => ({ ...prev, formPhoneNumber: e.target.value }))}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="+420 xxx xxx xxx"
                />
            </div>

            <div className="mb-4">
                <label htmlFor="subject" className="flex items-center text-gray-700 font-semibold mb-2">
                    <MessageSquare className="w-4 h-4 mr-2" />
                    Předmět
                </label>
                <input
                    type="text"
                    id="subject"
                    required
                    value={formData.subject}
                    onChange={(e) => setFormData(prev => ({ ...prev, subject: e.target.value }))}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
            </div>

            <div className="mb-6">
                <label htmlFor="message" className="flex items-center text-gray-700 font-semibold mb-2">
                    <MessageSquare className="w-4 h-4 mr-2" />
                    Zpráva
                </label>
                <textarea
                    id="message"
                    required
                    value={formData.message}
                    onChange={(e) => setFormData(prev => ({ ...prev, message: e.target.value }))}
                    rows={6}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
            </div>

            {submitStatus === 'success' && (
                <div className="mb-4 p-4 bg-green-100 text-green-700 rounded-lg flex items-center">
                    <SendHorizontal className="w-5 h-5 mr-2" />
                    Děkujeme za váš zájem. Ozveme se vám co nejdříve.
                </div>
            )}

            {submitStatus === 'error' && (
                <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-lg flex items-center">
                    <RefreshCw className="w-5 h-5 mr-2" />
                    Došlo k chybě. Prosím zkuste to znovu později.
                </div>
            )}

            <button
                type="submit"
                disabled={isSubmitting}
                className="w-full bg-blue-600 text-white font-bold py-3 px-6 rounded-lg hover:bg-blue-700 transition duration-300 disabled:opacity-50 flex items-center justify-center"
            >
                {isSubmitting ? (
                    <>
                        <RefreshCw className="w-5 h-5 mr-2 animate-spin" />
                        Odesílám...
                    </>
                ) : (
                    <>
                        <SendHorizontal className="w-5 h-5 mr-2" />
                        Odeslat zprávu
                    </>
                )}
            </button>
        </form>
    );
};

export default ContactForm;