import {useEffect, useState, useRef, useCallback} from 'react';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import API_URL from "../config";

// Initialize FingerprintJS
let fpPromise;
const initFingerprint = () => {
    if (!fpPromise) {
        fpPromise = FingerprintJS.load();
    }
    return fpPromise;
};

// Hook to get visitor ID
export const useVisitorId = () => {
    const [visitorId, setVisitorId] = useState(null);
    const visitorIdRef = useRef(null);

    useEffect(() => {
        const getVisitorId = async () => {
            try {
                const fp = await initFingerprint();
                const result = await fp.get();
                visitorIdRef.current = result.visitorId;
                setVisitorId(result.visitorId);
            } catch (error) {
                console.error('Error getting visitor ID:', error);
            }
        };

        if (!visitorIdRef.current) {
            getVisitorId();
        }
    }, []);

    return visitorId;
};

// Tracking state management
const COOLDOWN_DURATION = 30 * 1000;
const UPDATE_INTERVALS = [
    { duration: 1 * 60 * 1000, interval: 5 * 1000 },
    { duration: 2 * 60 * 1000, interval: 10 * 1000 },
    { duration: 5 * 60 * 1000, interval: 30 * 1000 },
    { duration: 15 * 60 * 1000, interval: 60 * 1000 },
    { duration: 60 * 60 * 1000, interval: 5 * 60 * 1000 },
    { duration: Infinity, interval: 15 * 60 * 1000 }
];

let isTrackingPaused = false;
let pauseTimeout = null;

const pauseTracking = () => {
    isTrackingPaused = true;
    if (pauseTimeout) {
        clearTimeout(pauseTimeout);
    }
    pauseTimeout = setTimeout(() => {
        isTrackingPaused = false;
        pauseTimeout = null;
    }, COOLDOWN_DURATION);
};

// Email tracking hook
export const useEmailTracking = () => {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const visitorId = useVisitorId();

    const trackEmailVisit = useCallback(async () => {
        if (!visitorId || isTrackingPaused) return;

        const customerId = searchParams.get('ci');
        if (!customerId) return;

        try {
            await fetch(`${API_URL}/api/tracking/email-click`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    recipientId: customerId,
                    path: location.pathname,
                    visitorId,
                }),
            });

            // Clean up URL after tracking
            const newSearchParams = new URLSearchParams(searchParams);
            ['utm_source', 'utm_medium', 'utm_campaign', 'ci'].forEach(param =>
                newSearchParams.delete(param)
            );

            const newSearch = newSearchParams.toString();
            const cleanPath = location.pathname + (newSearch ? `?${newSearch}` : '');

            if (location.search !== newSearch) {
                navigate(cleanPath, { replace: true });
            }
        } catch (error) {
            console.error('Error tracking email visit:', error);
            pauseTracking();
        }
    }, [location.pathname, visitorId, searchParams, navigate]);

    useEffect(() => {
        if (visitorId) {
            trackEmailVisit();
        }
    }, [trackEmailVisit, visitorId]);
};

// Page tracking hook
export const usePageTracking = () => {
    const location = useLocation();
    const visitorId = useVisitorId();
    const visibleTimeRef = useRef(0);
    const lastVisibleTimestampRef = useRef(null);
    const startTimeRef = useRef(new Date());
    const isSendingRef = useRef(false);
    const visibilityIntervalRef = useRef(null);
    const updateIntervalRef = useRef(null);
    const sessionIdRef = useRef(crypto.randomUUID());

    const updateVisibleTime = useCallback(() => {
        if (lastVisibleTimestampRef.current && !document.hidden) {
            visibleTimeRef.current += 1000;
        }
    }, []);

    const handleVisibilityChange = useCallback(() => {
        if (document.hidden) {
            if (lastVisibleTimestampRef.current) {
                visibleTimeRef.current += (new Date() - lastVisibleTimestampRef.current);
            }
            lastVisibleTimestampRef.current = null;
            if (visibilityIntervalRef.current) {
                clearInterval(visibilityIntervalRef.current);
            }
        } else {
            lastVisibleTimestampRef.current = new Date();
            if (visibilityIntervalRef.current) {
                clearInterval(visibilityIntervalRef.current);
            }
            visibilityIntervalRef.current = setInterval(updateVisibleTime, 1000);
        }
    }, [updateVisibleTime]);

    const sendTrackingData = useCallback(async (endTime, isUpdate = false) => {
        if (isSendingRef.current || isTrackingPaused || !visitorId) return;

        isSendingRef.current = true;
        const finalVisibleTime = visibleTimeRef.current;

        try {
            const endpoint = isUpdate ? 'page-view-update' : 'page-view';
            const response = await fetch(`${API_URL}/api/tracking-web/${endpoint}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    sessionId: sessionIdRef.current,
                    pageUrl: window.location.href,
                    path: location.pathname,
                    startTime: startTimeRef.current.toISOString(),
                    endTime: endTime.toISOString(),
                    duration: Math.round((endTime - startTimeRef.current) / 1000),
                    visibleDuration: Math.round(finalVisibleTime / 1000),
                    referrerUrl: document.referrer,
                    visitorId,
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error sending tracking data:', error);
            pauseTracking();
        } finally {
            isSendingRef.current = false;
        }
    }, [location.pathname, visitorId]);

    const setupUpdateInterval = useCallback(() => {
        if (updateIntervalRef.current) {
            clearInterval(updateIntervalRef.current);
        }

        const startTime = new Date();
        let currentIntervalIndex = 0;

        const scheduleNextUpdate = () => {
            const elapsedTime = new Date() - startTime;

            while (currentIntervalIndex < UPDATE_INTERVALS.length - 1 &&
            elapsedTime >= UPDATE_INTERVALS[currentIntervalIndex].duration) {
                currentIntervalIndex++;
            }

            const currentInterval = UPDATE_INTERVALS[currentIntervalIndex].interval;

            updateIntervalRef.current = setTimeout(() => {
                sendTrackingData(new Date(), true);
                scheduleNextUpdate();
            }, currentInterval);
        };

        sendTrackingData(new Date());
        scheduleNextUpdate();
    }, [sendTrackingData]);

    useEffect(() => {
        startTimeRef.current = new Date();
        visibleTimeRef.current = 0;
        lastVisibleTimestampRef.current = !document.hidden ? new Date() : null;
        isSendingRef.current = false;
        sessionIdRef.current = crypto.randomUUID();

        if (!document.hidden) {
            visibilityIntervalRef.current = setInterval(updateVisibleTime, 1000);
        }

        document.addEventListener('visibilitychange', handleVisibilityChange);
        setupUpdateInterval();

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            if (visibilityIntervalRef.current) {
                clearInterval(visibilityIntervalRef.current);
            }
            if (updateIntervalRef.current) {
                clearTimeout(updateIntervalRef.current);
            }
            sendTrackingData(new Date());
        };
    }, [location.pathname, handleVisibilityChange, updateVisibleTime, sendTrackingData, setupUpdateInterval]);
};

// Link tracking hook
export const useLinkTracking = () => {
    const visitorId = useVisitorId();

    const handleLinkClick = useCallback(async (event) => {
        const target = event.target.closest('a');
        if (!target || isTrackingPaused || !visitorId) return;

        try {
            await fetch(`${API_URL}/api/tracking-web/link-click`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    url: target.href,
                    text: target.textContent?.trim(),
                    elementId: target.id,
                    elementClass: target.className,
                    pageUrl: window.location.href,
                    timestamp: new Date().toISOString(),
                    referrerUrl: document.referrer,
                    visitorId,
                }),
            });
        } catch (error) {
            console.error('Error tracking link click:', error);
            pauseTracking();
        }
    }, [visitorId]);

    useEffect(() => {
        if (visitorId) {
            document.addEventListener('click', handleLinkClick);
            return () => document.removeEventListener('click', handleLinkClick);
        }
    }, [visitorId, handleLinkClick]);
};

export const useTracking = () => {
    usePageTracking();
    useLinkTracking();
    useEmailTracking();
};