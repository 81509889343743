import React, { useState } from 'react';
import { Calendar, Clock, Phone, SendHorizontal, RefreshCw, ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import API_URL from "../config";
import {useVisitorId} from "../hooks/useEmailTracking";
import {ScheduleAppointmentSEO} from "../SEO/SEOHelmet";

const ScheduleAppointmentPage = () => {
    const visitorId = useVisitorId();  // Use the hook
    const getNextWorkingDay = () => {
        const days = ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota'];
        const today = new Date();
        let nextDay = new Date(today);
        nextDay.setDate(today.getDate() + 1);

        while (nextDay.getDay() === 0 || nextDay.getDay() === 6) {
            nextDay.setDate(nextDay.getDate() + 1);
        }

        return days[nextDay.getDay()];
    };

    const [formData, setFormData] = useState({
        formPhoneNumber: '',
        selectedDay: getNextWorkingDay(),
        selectedTime: '14:00',
        subject: 'Žádost o zpětné volání',
        message: 'Prosím o zpětné volání ve vybraný čas.',
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatus, setSubmitStatus] = useState('idle');

    const days = ['Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek'];
    const times = Array.from({ length: 24 }, (_, i) =>
        `${String(i).padStart(2, '0')}:00`
    );

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const response = await fetch(`${API_URL}/api/customer/contact-form`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...formData,
                    isCallbackRequest: true,
                    callbackDay: formData.selectedDay,
                    callbackTime: formData.selectedTime,
                    formEmail: '',
                    message: `${formData.message}\n\nPreferovaný čas hovoru: ${formData.selectedDay} ${formData.selectedTime}`,
                    visitorId
                }),
            });

            if (response.ok) {
                setSubmitStatus('success');
                setFormData({
                    formPhoneNumber: '',
                    selectedDay: getNextWorkingDay(),
                    selectedTime: '14:00',
                    subject: 'Žádost o zpětné volání',
                    message: 'Prosím o zpětné volání ve vybraný čas.',
                });
            } else {
                setSubmitStatus('error');
            }
        } catch (error) {
            setSubmitStatus('error');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <ScheduleAppointmentSEO />
            <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white font-sans">
                <header className="bg-white shadow-sm">
                    <div className="container mx-auto px-6 py-4">
                        <Link to="/" className="inline-flex items-center text-blue-600 hover:text-blue-700 transition-colors">
                            <ArrowLeft className="w-5 h-5 mr-2" />
                            Zpět na hlavní stránku
                        </Link>
                    </div>
                </header>

                <main className="container mx-auto px-4 py-8">
                    <div className="max-w-md mx-auto">
                        <div className="bg-white rounded-xl shadow-lg p-6">
                            <div className="flex flex-col items-center mb-8">
                                <div className="w-20 h-20 bg-blue-50 rounded-full flex items-center justify-center mb-4">
                                    <img src="logo192.png" alt="" />
                                </div>
                                <h1 className="text-2xl font-semibold text-center mb-2">
                                    Naplánovat konzultaci
                                </h1>
                                <p className="text-gray-600 text-center">
                                    Vyberte si čas, který vám vyhovuje, a my vás budeme kontaktovat.
                                </p>
                            </div>

                            <form onSubmit={handleSubmit} className="space-y-6">
                                <div className="space-y-4">
                                    <div className="flex gap-4">
                                        <div className="flex-1">
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Den</label>
                                            <div className="relative">
                                                <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                                                <select
                                                    value={formData.selectedDay}
                                                    onChange={(e) => setFormData(prev => ({ ...prev, selectedDay: e.target.value }))}
                                                    className="w-full pl-10 pr-4 py-2 border rounded-lg appearance-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                                >
                                                    {days.map(day => (
                                                        <option key={day} value={day}>{day}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="flex-1">
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Čas</label>
                                            <div className="relative">
                                                <Clock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                                                <select
                                                    value={formData.selectedTime}
                                                    onChange={(e) => setFormData(prev => ({ ...prev, selectedTime: e.target.value }))}
                                                    className="w-full pl-10 pr-4 py-2 border rounded-lg appearance-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                                >
                                                    {times.map(time => (
                                                        <option key={time} value={time}>{time}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">Telefon</label>
                                        <div className="relative">
                                            <Phone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                                            <input
                                                type="tel"
                                                required
                                                value={formData.formPhoneNumber}
                                                onChange={(e) => setFormData(prev => ({ ...prev, formPhoneNumber: e.target.value }))}
                                                placeholder="+420 xxx xxx xxx"
                                                className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {submitStatus === 'success' && (
                                    <div className="p-4 bg-green-100 text-green-700 rounded-lg flex items-center">
                                        <SendHorizontal className="w-5 h-5 mr-2 flex-shrink-0" />
                                        <span>Děkujeme za váš zájem. Zavoláme vám ve vybraný čas.</span>
                                    </div>
                                )}

                                {submitStatus === 'error' && (
                                    <div className="p-4 bg-red-100 text-red-700 rounded-lg flex items-center">
                                        <RefreshCw className="w-5 h-5 mr-2 flex-shrink-0" />
                                        <span>Došlo k chybě. Prosím zkuste to znovu později.</span>
                                    </div>
                                )}

                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-4 rounded-lg transition duration-200 disabled:opacity-50 flex items-center justify-center"
                                >
                                    {isSubmitting ? (
                                        <>
                                            <RefreshCw className="w-5 h-5 mr-2 animate-spin" />
                                            Odesílám...
                                        </>
                                    ) : (
                                        <>
                                            <SendHorizontal className="w-5 h-5 mr-2" />
                                            Naplánovat hovor
                                        </>
                                    )}
                                </button>

                                <p className="text-xs text-gray-500 text-center">
                                    Odesláním formuláře souhlasíte se zpracováním osobních údajů v souladu s našimi podmínkami ochrany soukromí.
                                </p>
                            </form>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
};

export default ScheduleAppointmentPage;